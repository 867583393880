<template>
    <div>
        <vue-ueditor-wrap ref="editor" v-model="content" :config="editorConfig" :destroy="true" />
    </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
    components: {
        VueUeditorWrap
    },
    props:{
        details: {
            type: String,
            default: "",
        },
        typeStr: {
            type: String,
            default: "school",
        }
    },
    data() {
        return {
            content: this.details,
        // ueditor配置
            editorConfig: {
                autoHeightEnabled: false,  // 编辑器不自动被内容撑高
                autoFloatEnabled: false,
                initialContent: '请输入内容',
                autoClearinitialContent: true,
                initialFrameWidth: '99%', // 初始化宽
                initialFrameHeight: 308, // 初始化高
                BaseUrl: this.$http.defaults.baseURL,
                // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口） - 修改成自己后端地址
                serverUrl: this.$http.defaults.baseURL + '/upload/image/upload',
                videoUrl: this.$http.defaults.baseURL + '/upload/image/upload',
                // UEditor 资源文件的存放路径
                UEDITOR_HOME_URL: '/UEditor/', // 重要重要重要重要重要重要重要重要重要 必须配置好路径!!!
                typeName: "school",
            }
        }

    },
    created() {
        this.editorConfig.typeName = this.typeStr;
        console.log('执行子组件的')
        if(this.details){
            this.content = this.details;
        }
        console.log('details:', this.details)
        console.log('content:', this.content)
    },
    methods: {

    }
}
</script>

<style scoped>
</style>
<style>
.edui-editor-bottomContainer {display: none;}
/*#edui_fixedlayer {display: none;}*/

</style>
